// フラッシュメッセージの表示を3秒で消す
$(function() {
  setTimeout("$('.js-flash').fadeOut()", 3000);
});

// SPヘッダードロワーボタン
$(function () {
  $('.js-header_btn').click(function () {
    $(this).toggleClass('active')
    if ($(this).hasClass('active')) {
      $('html, body').css('overflow', 'hidden')
    } else {
      $('html, body').removeAttr('style')
    }
    $('.p-header_bg').fadeToggle()
    $('nav').toggleClass('open')
    $('.p-header_nav_links').toggleClass('open')
  })
  $('.p-header_bg, a[href^="#"]').click(function () {
    $('.p-header_bg').fadeOut()
    $('.js-header_btn').removeClass('active')
    $('.p-header_btn').css('position', 'absolute')
    $('html, body').removeAttr('style')
    $('nav').removeClass('open')
    $('.p-header_nav_links').removeClass('open')
    $('.p-header_bg').fadeOut()
  })
})

//slick
$(window).on('load', function () {
  $('.js-fv').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    speed: 3000,
    autoplaySpeed: 5000,
    swipe: true,
  })
})