import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import "@nathanvda/cocoon"
import "../stylesheets/front/application.scss"
import "../javascripts/front/common.js"

Rails.start()
ActiveStorage.start()
